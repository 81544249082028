<template>
  <div class="ca-login">
    <!-- top -->
    <div class="top">
      <span>帐号登录</span>
      <img
        @click="$router.push({name:'GeneralLogin'})"
        src="../../assets/images/键盘.png"
        alt="图片加载失败"
      />
    </div>

    <!-- middle -->
    <div class="middle">
      <p class="p1">
        <img src="../../assets/images/ca锁.png" alt />
      </p>
      <p class="p2">请安装云南CA证书助手</p>
    </div>

    <!-- bottom -->
    <div class="bottom">
      <el-button type="primary" round>登 录</el-button>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.ca-login {
  // top
  .top {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin-bottom: 40px;
    span {
      font-size: 14px;
      color: #737373;
      width: 80px;
      line-height: 30px;
      text-align: center;
      background: url("../../assets/images/圆角矩形 1.png");
      background-size: 100% 100%;
    }
    img {
      width: 60px;
      height: 58px;
      cursor: pointer;
    }
  }

  // middle
  .middle {
    margin-bottom: 60px;
    .p1 {
      img {
        width: 150px;
      }
      text-align: center;
      margin-bottom: 20px;
    }
    .p2 {
      color: #c9c9c9;
      font-size: 18px;
      text-align: center;
    }
  }

  // bottom
  .bottom {
    text-align: center;
    .el-button {
      font-size: 16px;
      width: 270px;
      height: 40px;
    }
  }
}
</style>